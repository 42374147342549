/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Removes the annoying button animation on page load */
.btn {
    animation: none;
    &:active:hover,
    &:active:focus {
        animation: button-pop var(--animation-btn, 0.25s) ease-out;
        transform: scale(var(--btn-focus-scale, 0.97));
    }
}

:root {
    view-transition-name: none;
}
